import * as React from "react";
import {
  Body2,
  Caption2,
  Body1,
  Body1Strong,
  Body1Stronger,
  Caption1,
  Caption1Strong,
  Caption1Stronger,
  Subtitle2,
  Subtitle2Stronger,
  Subtitle1,
  Title3,
  Title2,
  Title1,
  LargeTitle,
  Display,
  makeStyles,
} from "@fluentui/react-components";
import { PincitesColor } from "./PincitesTheme";

export enum PincitesTextType {
  CAPTION2 = "Caption2" /* For error messages or smallest supporting text */,
  BODY2 = "Body2" /* Unused, larger than BODY1 */,
  BODY1 = "Body1" /* For most text, as our default */,
  BODY1STRONG = "Body1Strong",
  BODY1STRONGER = "Body1Stronger",
  CAPTION1 = "Caption1" /* For smaller, supporting text */,
  CAPTION1STRONG = "Caption1Strong",
  CAPTION1STRONGER = "Caption1Stronger",
  SUBTITLE2 = "Subtitle2",
  SUBTITLE2STRONGER = "Subtitle2Stronger",
  SUBTITLE1 = "Subtitle1" /* For clause view headers */,
  TITLE3 = "Title3" /* For dialog header */,
  TITLE2 = "Title2" /* Unused */,
  TITLE1 = "Title1" /* Unused */,
  LARGETITLE = "LargeTitle" /* For the home page title */,
  DISPLAY = "Display" /* Unused */,
}

export enum PincitesFontType {
  WHITE = "white",
  GRAY = "gray",
  GRAY_UPPERCASE = "grayUppercase",
  ITALIC = "italic",
  NONE = "none",
}

const useStyles = makeStyles({
  default: {
    color: PincitesColor.NEUTRAL,
  },
  gray: {
    color: PincitesColor.GRAY_TEXT,
  },
  grayUppercase: {
    color: PincitesColor.GRAY_TEXT,
    textTransform: "uppercase",
  },
  white: {
    color: PincitesColor.WHITE_ALWAYS,
  },
  italic: {
    fontStyle: "italic",
  },
});

export function PincitesText({
  textType = PincitesTextType.BODY1,
  children,
  fontType = PincitesFontType.NONE,
}: {
  textType?: PincitesTextType;
  children: React.ReactNode;
  fontType?: PincitesFontType;
}) {
  const styles = useStyles();

  const TextComponent =
    {
      [PincitesTextType.CAPTION2]: Caption2,
      [PincitesTextType.BODY2]: Body2,
      [PincitesTextType.BODY1]: Body1,
      [PincitesTextType.BODY1STRONG]: Body1Strong,
      [PincitesTextType.BODY1STRONGER]: Body1Stronger,
      [PincitesTextType.CAPTION1]: Caption1,
      [PincitesTextType.CAPTION1STRONG]: Caption1Strong,
      [PincitesTextType.CAPTION1STRONGER]: Caption1Stronger,
      [PincitesTextType.SUBTITLE2]: Subtitle2,
      [PincitesTextType.SUBTITLE2STRONGER]: Subtitle2Stronger,
      [PincitesTextType.SUBTITLE1]: Subtitle1,
      [PincitesTextType.TITLE3]: Title3,
      [PincitesTextType.TITLE2]: Title2,
      [PincitesTextType.TITLE1]: Title1,
      [PincitesTextType.LARGETITLE]: LargeTitle,
      [PincitesTextType.DISPLAY]: Display,
    }[textType] || React.Fragment;

  const className = getClassNameFromPincitesFontType(fontType);
  const customStyle = className ? styles[className] : "";

  return <TextComponent className={customStyle}>{children}</TextComponent>;
}

function getClassNameFromPincitesFontType(pincitesFontType: PincitesFontType): keyof ReturnType<typeof useStyles> {
  switch (pincitesFontType) {
    case PincitesFontType.GRAY_UPPERCASE:
      return "grayUppercase";
    case PincitesFontType.GRAY:
      return "gray";
    case PincitesFontType.WHITE:
      return "white";
    default:
      return "default";
  }
}
